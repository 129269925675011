<template>
  <b-row
    class="misc-wrapper p-2"
    style="height: 100vh; width: auto"
  >
    <!-- Coming soon page-->
    <b-col
      md="12"
      style="min-height: 13%;"
    >
      <b-link
        class="brand-logo"
        :to="{name:'home'}"
      >
        <vuexy-logo />
      </b-link>
    </b-col>
    <div
      class="misc-inner px-2"
      style="min-height: 87%;"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Encuentre su UBIGEO
        </h2>
        <p
          class="mb-5"
          style="text-align: justify"
        >
          Seleccione en cada campo su Departamento, Provincia y Districto para obtener el código UBIGEO. Debe copiarlo y pegarlo en el formulario
        </p>
        <b-card
          class="p-2"
        >
          <b-form-group
            label-cols-md="3"
            content-cols-md="8"
            label="Departamento"
            label-for="region"
          >
            <b-form-select
              id="region"
              v-model="region"
              :options="optionsRegions"
              value-field="id"
              text-field="name"
              name="region"
            />
          </b-form-group>
          <b-form-group
            label-cols-md="3"
            content-cols-md="8"
            label="Provincia"
            label-for="province"
          >
            <b-form-select
              id="province"
              v-model="province"
              :options="optionsProvinces"
              value-field="id"
              text-field="name"
              name="province"
              :disabled="( region ==='' )"
            />
          </b-form-group>
          <b-form-group
            label-cols-md="3"
            content-cols-md="8"
            label="Distrito"
            label-for="district"
          >
            <b-form-select
              id="district"
              v-model="district"
              :options="optionsDistricts"
              value-field="value"
              text-field="name"
              name="district"
              :disabled="( province ==='' )"
            />
          </b-form-group>
          <b-form-group
            label-cols-md="3"
            content-cols-md="8"
            label="Ubigeo"
            label-for="ubigee"
            class="my-3"
          >
            <b-input-group>
              <b-form-input
                id="ubigee"
                v-model="ubigee"
                name="ubigee"
                readonly
              />
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="copyUbigee(ubigee)"
                >
                  Copiar ubigeo
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
      </div>
    </div>
  <!-- / Coming soon page-->
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormSelect, BFormGroup, BCard, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BLink,
    BButton,
  },
  data() {
    return {
      variables: [],
      optionsRegions: [],
      region: '',
      province: '',
      district: '',
    }
  },
  async created() {
    if (this.region === '') {
      /* eslint-disable */
      const { data: { variables } } = await useJwt.getVariables({category:"Ubigeo"})
      this.variables = variables
      this.optionsRegions = variables.filter(variable => variable.type == "Departamento")
    }
  },
  computed: {
    optionsProvinces() {
      if (this.region !== '') {
        return this.variables.filter(variable => variable.type == "Provincia" && variable.parentId == this.region)
      }
      return []
    },
    optionsDistricts() {
      if (this.region !== '' && this.province !== '') {
        return this.variables.filter(variable => variable.type == "Distrito" && variable.parentId == this.province)
      }
      return [] 
    },
    ubigee() {
      if (this.district !== '' && this.district !== undefined) {
        return this.district
      }
      return ''
    },
  },
  methods: {
    copyUbigee(ubigee){
      let content = document.getElementById('ubigee');
      content.select();
      document.execCommand('copy');
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
